import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="container my-5">
            <div className="boxStyle text-center">
                <img src="P1.png" alt="Lucky Firework" className="login-logo" style={{width: '20%'}} />
            </div>
            <div className="card shadow-lg rounded">
                <div className="card-body">
                    <h1 className="mb-4">Privacy Policy for LuckyFireworks App</h1>

                    <p className="card-text">
                        This Privacy Policy outlines the collection, use, and disclosure practices of LuckyFireworks ("we," "us," or "our") in relation to the information we collect through your use of our App. It is important that you read and understand this policy to know how your personal data is handled.
                    </p>

                    <h3 className="mt-4">1. Information We Collect</h3>
                    <ul className="list-unstyled">
                        <p>We may collect the following types of information when you use our App:</p>
                        <li><strong>Personal Information:</strong> Information such as name, email address, or other identifiers provided directly by you.</li>
                        <li><strong>Usage Data:</strong> Information automatically collected as you interact with the App, including device information, IP address, and browsing data.</li>
                        <li><strong>Location Data:</strong> If applicable, we may collect information about your location through your device’s GPS or other location-tracking methods.</li>
                    </ul>

                    <h3>2. How We Use Your Information</h3>
                    <ul className="list-unstyled">
                        <li>The information we collect is used to:</li>
                        <li>Provide, maintain, and improve the functionality of the App.</li>
                        <li>Personalize your experience.</li>
                        <li>Respond to customer service requests and support needs.</li>
                        <li>Send updates, promotions, and other communications related to the App.</li>
                        <li>Comply with legal obligations and protect our legal rights.</li>
                    </ul>

                    <h3>3. Sharing of Your Information</h3>
                    <ul className="list-unstyled">
                        <p>We do not sell, rent, or share your personal information with third parties except in the following cases:</p>
                        <li><strong>Service Providers:</strong> We may share information with trusted third-party service providers who assist us in operating our App or conducting our business.</li>
                        <li><strong>Legal Compliance:</strong> We may disclose your information to comply with legal requirements, such as court orders, subpoenas, or government regulations.</li>
                    </ul>

                    <h3>4. Data Security</h3>
                    <p>
                        We take reasonable steps to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the Internet or method of electronic storage is 100% secure.
                    </p>

                    <h3>5. Your Privacy Rights</h3>
                    <ul className="list-unstyled">
                        <li>Access, correct, or delete the personal information we hold about you.</li>
                        <li>Object to or restrict the processing of your data.</li>
                        <li>Withdraw your consent at any time where we rely on consent for processing your personal data.</li>
                    </ul>

                    <h3>6. Changes to This Privacy Policy</h3>
                    <p>
                        We may update this Privacy Policy from time to time. Any changes will be posted within the App and will be effective immediately upon posting. We encourage you to review the policy periodically.
                    </p>
                </div>
            </div>
        </div>
  )
}

export default PrivacyPolicy