
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/style/singup.css';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import React from 'react';
import Login from "./components/Login"
import DashboardLayout from './components/DashboardLayout'
import useToken from './Auth/useToken';
import PrivacyPolicy from './components/PrivacyPolicy';



function App() {
  const { token, setToken } = useToken();
  if (!token) {
    // return <Login setToken={setToken} />
    return <Router>
      <Switch>
        <Route path="/privacy-policy" render={() => <PrivacyPolicy />} />
        <Route path="/" render={() => <Login setToken={setToken} />} />
      </Switch>
    </Router>
  }
  return (
    <Router>
      <Switch>
        <div className="wrapper">
          <DashboardLayout />,
        </div>
        <Redirect from="/" to="/dashboard" />
      </Switch>
    </Router>
  )
}

export default App

